const BrandsData = [
    {
        id: "1",
        image: "./images/logo-kith.png"
    },
    {
        id: "2",
        image: "./images/logo-lrg.png"
    },
    {
        id: "3",
        image: "./images/logo-hottopic.png"
    },
    {
        id: "4",
        image: "./images/logo-neff.png"
    },
    {
        id: "5",
        image: "./images/logo-birdwell.png"
    },
    {
        id: "6",
        image: "./images/logo-aime.png"
    },
    {
        id: "7",
        image: "./images/logo-amiri.png"
    },
    {
        id: "8",
        image: "./images/logo-nest.png"
    },
    {
        id: "9",
        image: "./images/logo-johnnie.png"
    },
    {
        id: "10",
        image: "./images/logo-modernica.png"
    },
    {
        id: "11",
        image: "./images/logo-stampd.png"
    },
    {
        id: "11",
        image: "./images/logo-savekhaki.png"
    },
    {
        id: "11",
        image: "./images/logo-dose.png"
    }
]
export default BrandsData